import { Show, createSignal } from 'solid-js'
import { Component } from 'solid-js'
import { CommentEntryProps } from '~/components/comment-entry/comment-entry.interfaces'
import styles from '~/components/comment-entry/comment-entry.module.scss'
import ConfirmationModal from '~/components/confirmation-modal/confirmation-modal'
import RowButton from '~/components/row-button/row-button'
import { deleteComment } from '~/services/database/comments'
import { deleteComment as deleteCommentInView } from '~/services/current-document/comments'
import { user } from '~/services/database/users'
import UserIcon from '~/assets/icons/user.svg'
import CheckIcon from '~/assets/icons/check.svg'
import DeleteIcon from '~/assets/icons/delete.svg'
import { translations } from '~/translations'

const CommentEntry: Component<CommentEntryProps> = ({ comment }) => {
  const isOwn = () => (
    user()?.id === comment.user_id
  )

  const classList = () => ({
    [styles.container]: true,
    [styles.isOwn]: isOwn()
  })

  const [openConfirmationModal, setConfirmationModal] = createSignal(false)

  const onConfirmDelete = () => {
    setConfirmationModal(true)
  }

  const onDelete = () => {
    deleteComment({
      id: comment.id
    }).then(() => {
      deleteCommentInView(comment)
    }).finally(() => {
      setConfirmationModal(false)
    })
  }

  return (
    <div classList={classList()}>
      <div class={styles.head}>
        <div class={styles.author}>
          <UserIcon class={styles.icon} />
          {comment.user?.name}
        </div>
        {isOwn() && (
          <RowButton
            onClick={onConfirmDelete}
            icon={DeleteIcon}
          />
        )}
      </div>

      <Show when={comment.resolved}>
        <div class={styles.resolved}>
          <CheckIcon class={styles.icon} />
        </div>
      </Show>

      <ConfirmationModal
        type='danger'
        onConfirm={onDelete}
        title={translations().editor.comments.deleteConfirmation.title}
        description={translations().editor.comments.deleteConfirmation.description}
        open={openConfirmationModal()}
        setOpen={setConfirmationModal}
      />
      
      <div class={styles.message}>
        {comment.text}
      </div>
    </div>
  )
}

export default CommentEntry
