import { Component, For, Show, createSignal } from 'solid-js'
import CommentEntry from '~/components/comment-entry/comment-entry'
import { CommentsProps } from '~/components/comments/comments.interfaces'
import styles from '~/components/comments/comments.module.scss'
import CommentForm from '~/components/forms/comment-create/comment-create'
import SmallButton from '~/components/small-button/small-button'
import { chapter } from '~/services/current-document/chapter'
import { translations } from '~/translations'

const Comments: Component<CommentsProps> = ({ entry, visible, deployByDefault }) => {
  const toggled = () => entry.highlighted || visible
  const classList = () => ({
    [styles.comments]: true,
    [styles.highlighted]: toggled(),
    [styles.typesetting]: true
  })

  const chapterId = chapter()?.id

  const [showComments, setShowComments] = createSignal(deployByDefault ?? false)

  const onToggleComments = () => {
    setShowComments(!showComments())
  }

  return (
    <div classList={classList()}>
      <Show when={!toggled() && entry.comments.length > 0}>
        <div class={styles.commentsCount}>
          {translations().editor.comments.count(entry.comments.length)}
        </div>
      </Show>
      <Show when={toggled()}>
        <div class={styles.floatingControls}>
          {showComments() ? (
            <SmallButton onClick={onToggleComments}>
              {translations().general.actions.fold}
            </SmallButton>
          ) : (
            <SmallButton onClick={onToggleComments}>
              {entry.comments.length > 0 ? translations().editor.comments.view : translations().editor.comments.add}
            </SmallButton>
          )}
        </div>
        <Show when={showComments()}>
          <div class={styles.commentsContainer}>
            <Show when={entry.comments.length > 0}>
              <div class={styles.commentsList}>
                <For each={entry.comments}>
                  {comment => (
                    <CommentEntry comment={comment} />
                  )}
                </For>
              </div>
            </Show>

            {chapterId && (
              <CommentForm
                chapterId={chapterId}
                translationId={entry.id}
              />
            )}
          </div>
        </Show>
      </Show>
    </div>
  )
}

export default Comments
