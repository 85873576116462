import { Component, createSignal } from 'solid-js'
import { useNavigate } from '@solidjs/router'
import Button from '~/components/button/button'
import styles from '~/components/validate-chapter-step/validate-chapter-step.module.scss'
import { chapter } from '~/services/current-document/chapter'
import { updateChapter } from '~/services/database/chapters'
import { Job } from '~/types/comic/chapter'
import BookIcon from '~/assets/icons/book.svg'
import CheckIcon from '~/assets/icons/check.svg'
import { ValidateChapterStepProps, ValidationMessage } from '~/components/validate-chapter-step/validate-chapter-step.interfaces'
import ConfirmationModal from '~/components/confirmation-modal/confirmation-modal'
import { translations } from '~/translations'

const ValidateChapterStep: Component<ValidateChapterStepProps> = ({ mode, aside }) => {
  const navigate = useNavigate()
  const [nextStep, setNextStep] = createSignal<Job>()
  const [validationMessage, setValidationMessage] = createSignal<ValidationMessage>()
  const [open, setOpen] = createSignal(false)

  const onConfirm = () => {
    const step = nextStep()
    const currentChapter = chapter()

    if(step && currentChapter){
      updateChapter({
        id: currentChapter.id,
        progress: step
      }).then(() => {
        navigate(`/series/${currentChapter.comicId}`)
      })
    }
  }

  const updateProgressTo = (progress: Job, validationMessage: ValidationMessage) => () => {
    setNextStep(progress)
    setValidationMessage(validationMessage)
    setOpen(true)
  }

  return (
    <>
      <ConfirmationModal
        onConfirm={onConfirm}
        open={open()}
        setOpen={setOpen}
        title={validationMessage()?.title ?? ''}
        description={validationMessage()?.description ?? ''}
        confirmIcon={CheckIcon}
        confirmLabel={'Confirmer'}
      />
      <div class={styles.validateChapterStep}>
        {aside && (
          <div class={styles.aside}>
            {aside}
          </div>
        )}
        <div class={styles.progressButtons}>
          {mode === 'translation' && (
            <Button
              icon={CheckIcon}
              onClick={updateProgressTo('proofreading', translations().editor.stepConfirmations.validateTranslation)}
            >
              {translations().editor.stepConfirmations.validateTranslation.title}
            </Button>
          )}
          {mode === 'proofreading' && (
            <>
              <Button
                icon={BookIcon}
                onClick={updateProgressTo('translation', translations().editor.stepConfirmations.backToTranslation)}
              >
                {translations().editor.stepConfirmations.backToTranslation.title}
              </Button>
              <Button
                icon={CheckIcon}
                onClick={updateProgressTo('typesetting', translations().editor.stepConfirmations.validateProofreading)}
              >
                {translations().editor.stepConfirmations.validateProofreading.title}
              </Button>
            </>
          )}
          {mode === 'typesetting' && (
            <>
              <Button
                icon={CheckIcon}
                onClick={updateProgressTo('typeProofreading', translations().editor.stepConfirmations.validateTypesetting)}
              >
                {translations().editor.stepConfirmations.validateTypesetting.title}
              </Button>
            </>
          )}
          {mode === 'typeProofreading' && (
            <>
              <Button
                icon={BookIcon}
                onClick={updateProgressTo('typesetting', translations().editor.stepConfirmations.backToTypesetting)}
              >
                {translations().editor.stepConfirmations.backToTypesetting.title}
              </Button>
              <Button
                icon={CheckIcon}
                onClick={updateProgressTo('validation', translations().editor.stepConfirmations.validateTypeProofreading)}
              >
                {translations().editor.stepConfirmations.validateTypeProofreading.title}
              </Button>
            </>
          )}
          {mode === 'validation' && (
            <>
              <Button
                icon={BookIcon}
                onClick={updateProgressTo('typesetting', translations().editor.stepConfirmations.backToTypesetting)}
              >
                {translations().editor.stepConfirmations.backToTypesetting.title}
              </Button>
              <Button
                icon={CheckIcon}
                onClick={updateProgressTo('completed', translations().editor.stepConfirmations.validateValidation)}
              >
                {translations().editor.stepConfirmations.validateValidation.title}
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ValidateChapterStep
