import { translations, updateTranslationComments } from '~/services/current-document/translations'
import { Comment } from '~/types/comic/comments'

const deleteComment = (comment: Comment) => {
  const translation = translations().find(entry => entry.id === comment.entry_id)
  if(translation){
    const comments = translation.comments.filter(entry => entry.id !== comment.id)
    updateTranslationComments(translation.id, comments)
  }
}

export {
  deleteComment
}