import { InferOutput, object, string, boolean } from 'valibot'

export interface CommentCreateFormProps{
  chapterId: string
  translationId: string
}

export const CommentCreateSchema = object({
  text: string(),
  resolved: boolean(),
})

export type CommentCreateFormType = InferOutput<typeof CommentCreateSchema>