import { Component } from 'solid-js'
import { SubmitHandler, createForm, reset, valiForm } from '@modular-forms/solid'
import styles from '~/components/forms/comment-create/comment-create.module.scss'
import { CommentCreateFormProps, CommentCreateFormType, CommentCreateSchema } from '~/components/forms/comment-create/comment-create.interfaces'
import { createComment } from '~/services/database/comments'
import { CreateCommentPayload } from '~/types/database/comments'
import SmallButton from '~/components/small-button/small-button'
import { translations, updateTranslationComments } from '~/services/current-document/translations'
import { user } from '~/services/database/users'
import { Comment } from '~/types/comic/comments'
import { translations as translationsDict } from '~/translations'

const CommentForm: Component<CommentCreateFormProps> = ({ chapterId, translationId }) => {
  const [form, { Form, Field }] = createForm<CommentCreateFormType>({
    validate: valiForm(CommentCreateSchema),
    initialValues: {
      text: '',
      resolved: false
    }
  })

  const onSubmit: SubmitHandler<CommentCreateFormType> = async (values) => {
    const payload: CreateCommentPayload = {
      chapter_id: chapterId,
      entry_id: translationId,
      ...values
    }

    createComment(payload).then(result => {
      const comments = translations().find(entry => entry.id === translationId)?.comments ?? []
      const newEntry: Comment = {
        ...result,
        user: {
          name: user()?.name ?? ''
        }
      }
      comments.push(newEntry)
      updateTranslationComments(translationId, comments)
    }).finally(() => {
      reset(form)
    })
  }

  return (
    <Form onSubmit={onSubmit} class={styles.form}>
      <div class={styles.container}>
        <div class={styles.fields}>
          <Field name="text">
            {(field, props) => (
              <textarea
                class={styles.textarea}
                placeholder={translationsDict().editor.comments.placeholder}
                {...field}
                {...props}
              />
            )}
          </Field>
          <Field name="resolved" type='boolean'>
            {(field, props) => (
              <label class={styles.checkboxContainer}>
                <input
                  type='checkbox'
                  class={styles.checkbox}
                  {...props}
                  checked={field.value}
                />
                <span class={styles.checkboxLabel}>
                  {translationsDict().editor.comments.markAsResolved}
                </span>
              </label>
            )}
          </Field>
        </div>
        <SmallButton type='submit'>
          {translationsDict().general.actions.send}
        </SmallButton>
      </div>
    </Form>
  )
}

export default CommentForm